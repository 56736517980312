/* @flow */
/* Edit view for uploads, we have to have some photos in the store to display this */

/** @jsxRuntime classic */
/** @jsx jsx */
import classnames from 'classnames';
import { jsx } from 'theme-ui'; // eslint-disable-line
import { Close } from '@eyeem-ui/icons';
import { Buttonizer, Box } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Icon, styleUtils } from 'eyeem-components';
import styled from 'styled-components';

import Photo from '../photo/';
import TooltipTrigger from '../tooltipTrigger/';

import {
  TEST_UPLOADPAGE_LOWRESICON,
  TEST_UPLOADPAGE_IMAGECONTAINER,
} from '../../../constants/pageObjectSelectors';

import { TOOLTIP_POSITION_BELOW_CENTER } from '../../../constants/misc';
import { preventAndStop, isOneOf } from '../../../helpers/tools';

const StyledIcon = styled(Icon)`
  border-radius: 24px;
  opacity: 1;
  path {
    stroke: ${styleUtils.fromTheme('colors.solidColors.orange')};
    transition: stroke 0.2s ease-in-out;
  }
  &:hover {
    path {
      stroke: ${styleUtils.fromTheme('colors.solidColors.orangeHover')};
    }
  }
`;

export const closeIconPosition = (ratio) => {
  const photoPadding = 8;
  const iconPadding = 12;
  const boxSize = 250;
  const fixedIconOffset = photoPadding + iconPadding;

  const pos = {
    top: fixedIconOffset,
    right: fixedIconOffset,
  };

  if (ratio > 1) {
    pos.top = (boxSize - photoPadding - boxSize / ratio) / 2 + fixedIconOffset;
  } else if (ratio < 1) {
    pos.right =
      (boxSize - photoPadding - boxSize * ratio) / 2 + fixedIconOffset;
  }

  return pos;
};

const lowResIconStyle = (ratio) => {
  const photoPadding = 8;
  const iconPadding = 12;
  const boxSize = 250;
  const fixedIconOffset = photoPadding + iconPadding;

  const style = {
    bottom: fixedIconOffset,
    right: fixedIconOffset,
    position: 'absolute',
  };

  if (ratio > 1) {
    style.bottom =
      (boxSize - photoPadding - boxSize / ratio) / 2 + fixedIconOffset;
  } else if (ratio < 1) {
    style.right =
      (boxSize - photoPadding - boxSize * ratio) / 2 + fixedIconOffset;
  }

  return style;
};

function SelectGridItem(props: {
  photo: UploadPhoto | DropzonePhoto | EyeEmPhoto,
  selection: $ReadOnlyArray<string>,
  removePhoto: Function,
  toggleSelection?: Function,
  lowResolution: number,
  isTablet?: boolean,
  isMobile: boolean,
}) {
  const { photo } = props;
  const photoId = photo.uuid || photo.id;
  const selected =
    photoId && props.selection && isOneOf(photoId, props.selection);
  const isLowResolution =
    props.lowResolution && photo.height * photo.width < props.lowResolution;

  const touch = props.isMobile || props.isTablet;

  // const hasEyeemPhotoCharacters = isEyeemPhoto(props.photo);
  // dropzone images have dataUrl
  const hasPhotoSource = !!photo.dataUrl || !!photo.url || !!photo.previewUrl;

  const className = classnames('selectGrid_imageContainer', {
    'selectGrid_imageContainer-empty': !hasPhotoSource,
    'selectGrid_imageContainer-touch': touch,
    'selectGrid_imageContainer-noHover': !props.toggleSelection,
  });

  return (
    <div
      className={className}
      data-test-id={TEST_UPLOADPAGE_IMAGECONTAINER}
      onClick={preventAndStop({
        callback: props.toggleSelection,
        payload: { id: photoId },
      })}>
      <div className="selectGrid_imageContainer_wrapper">
        {hasPhotoSource && (
          <Photo
            key={photoId}
            className={classnames('selectGrid_photo', {
              'selectGrid_photo-selected': selected,
            })}
            url={photo.url || photo.previewUrl}
            size1="w"
            size2="250"
            dataUrl={photo.dataUrl}
          />
        )}
      </div>
      {props.removePhoto && hasPhotoSource && (
        <ThemeProvider>
          <Buttonizer
            sx={{
              ...closeIconPosition(photo.ratio),
              position: 'absolute',
              display: 'none',
              cursor: 'pointer',
            }}
            className="selectGrid_deletePhoto"
            onClick={preventAndStop({
              callback: props.removePhoto,
              payload: photoId,
            })}>
            <Box pl={1}>
              <Close size="12" />
            </Box>
          </Buttonizer>
        </ThemeProvider>
      )}
      {isLowResolution && (
        <TooltipTrigger
          style={lowResIconStyle(photo.ratio)}
          panelPosition={TOOLTIP_POSITION_BELOW_CENTER}
          content={[{ body: 'upload.lowResolutionHint' }]}
          trigger={
            <div
              className="g_tooltip_trigger selectGrid_lowResIcon"
              data-test-id={TEST_UPLOADPAGE_LOWRESICON}>
              <StyledIcon type="info" size="16" />
            </div>
          }
        />
      )}
    </div>
  );
}

export default SelectGridItem;
